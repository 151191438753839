input, img {
  vertical-align: middle;
}

input, select{
  background:transparent;
  color:#fff;
  border:1px solid #000;
  padding:0;
  box-sizing: border-box;
  margin:0;
  height:30px;
  line-height:30px;
  border-radius:3px;
  text-align: center;
}

option{
  color:#000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
